<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.isKeepAlive" />
    </keep-alive>

    <router-view v-if="!$route.meta.isKeepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

body {
  overflow: auto !important;
}


body,
html,
h2{
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  background: #050713;
  color: #FFFFFF;
  font-family: Arial;
}

h2 {
  font-family: "dharma-gothic-e";
}

.font-dge {
  font-family: "dharma-gothic-e";
}


img[src = ""], img:not([src]) {
  opacity: 0;
}

img {
  -webkit-user-drag: none;
}

/* 样式适用于小于750分辨率 */
@media (max-width: 750px) {
  .el-select-dropdown__item {
    height: 80px !important;
    display: flex;
    align-items: center;
    font-size: 28px !important;
  }
}

/* 样式适用于大于750分辨率 */
@media (min-width: 751px) {
  .el-select-dropdown__item {
    height: 60px !important;
    display: flex;
    align-items: center;
    font-size: 22px !important;
  }
}



</style>
