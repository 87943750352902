import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMoviesTag: {
      onShow: "1",
      coming: '1',
    },
    sortDirection: "left",

  },
  mutations: {
    CHANGE_SORT_DIRECTION(state, params) {
      state.sortDirection = params != 0 ? 'right' : 'left';
    },
    CHANGE_SHOW_MOVIES_TAG (state, params) {
      let {onShow, coming} = params;
      state.showMoviesTag = {onShow, coming};
    }
  },
  actions: {
  },
  modules: {
  }
})
