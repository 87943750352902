// 域名
// const domain = "localhost:9981";
const domain = window.location.host + "/api";

// 端口
const port = null;

const path = "";

// 路径
const root = window.location.protocol + "//" + domain + (port ? ":" + port : "") + path;

// 接口
const api = {

};

export default Object.assign(
    {
        $base: {
            domain: domain,
            port: port,
            root: root
        },

        imgRoot: `${root}/commonFile/download?code=`,

        // 电影
        film: {
            list:`${root}/film/list`,
            detail:`${root}/film/detail`,
            ticketList:`${root}/ticket/list`,
            listSort:`${root}/film/listSort`,
            getPoster:`${root}/ticket/getPoster`,
        },

        // 留言
        message: {
            add:`${root}/message/add`,
        },

        // 资讯
        news: {
            list: `${root}/news/list`,
            detail: `${root}/news/detail`,
        },

        param: {
              //  首页背景图
            detail: `${root}/param/detail?code=b83198149b174cefb417e9e5713e4568`,
            showTag: `${root}/param/detail?code=b83198149b174cefb417e9ewis758568`,
            // 排序
            sortModule: `${root}/param/detail?code=b83fj8149b174c89b417e9ewis758568`
        },

        subscribe: {
            add: `${root}/subscribe/add`
        },

        country: {
            list: `${root}/country/list`,
            poster: `${root}/poster/detail`
        }

    },
    api
);
