import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const redirect = function (mobile, pc) {
    return function () {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return isMobile ? {name: mobile} : {name: pc}
    }
}

const routes = [
    {
        path: '/',
        redirect: redirect("indexMobile", "indexPc")
    },
    {
        path: '/index/p',
        name: 'indexPc',
        meta: {isMobile: false, isKeepAlive: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexPC.vue'),
    },
    {
        path: '/index/m',
        name: 'indexMobile',
        meta: {isMobile: true, isKeepAlive: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexMobile.vue'),
    },
    {
        path: '/movies/detail/p',
        meta: {isMobile: false},
        name: 'moviesDetailPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/movies/Detail.vue'),
    },
    {
        path: '/movies/detail/m',
        meta: {isMobile: true},
        name: 'moviesDetailMobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/movies/DetailM.vue'),
    },
    {
        path: '/movies/list/p',
        meta: {isMobile: false, isKeepAlive: true},
        name: 'moviesListPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/movies/List.vue'),
    },
    {
        path: '/movies/list/m',
        meta: {isMobile: true, isKeepAlive: true},
        name: 'moviesListMobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/movies/ListM.vue'),
    },
    {
        path: '/news/detail/p',
        meta: {isMobile: false},
        name: 'newsDetailPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/news-detail/NewsDetail.vue'),
    },
    {
        path: '/news/detail/m',
        meta: {isMobile: true},
        name: 'newsDetailMobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/news-detail/NewsDetailM.vue'),
    },
    {
        path: '/about-us/p',
        meta: {isMobile: false, isKeepAlive: true},
        name: 'aboutUsPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/about-us/AboutUs.vue'),
    },
    {
        path: '/about-us/m',
        meta: {isMobile: true, isKeepAlive: true},
        name: 'aboutUsMobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/about-us/AboutUsM.vue'),
    },
    {
        path: '/contact/info/p',
        meta: {isMobile: false, isKeepAlive: true},
        name: 'contactInfoPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact/ContactInfo.vue'),
    },
    {
        path: '/contact/info/m',
        meta: {isMobile: true, isKeepAlive: true},
        name: 'contactInfoMobile',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact/ContactM.vue'),
    },
    {
        path: '/privacy/p',
        meta: {isMobile: false},
        name: 'privacyPc',
        component: () => import(/* webpackChunkName: "about" */ '../views/privacy/PrivacyPc.vue'),
    },
    {
        path: '/privacy/m',
        meta: {isMobile: true},
        name: 'privacyM',
        component: () => import(/* webpackChunkName: "about" */ '../views/privacy/PrivacyM.vue'),
    },

]


const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    // window.scrollTo(0, 0);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let toEnv = to.meta.isMobile;
    if(isMobile && !toEnv) {
        next('/');
    } else if(!isMobile && toEnv) {
        next('/');
    } else {
        next();
    }
});

export default router
